import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import PermissionedRoute from "@/core/route/permissioned-route/PermissionedRoute"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import MetaDetails from "@/main/page/MetaDetails"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"
import { Switch } from "react-router"
import { Redirect, Route } from "react-router-dom"

const AdminDashboard = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "admin-dashboard" */ "@/admin/dashboard/AdminDashboard")
)
const AdminSettingsFlow = lazyWithRetry(
  () => import(/* webpackChunkName: "admin-settings" */ "./settings/AdminSettingsFlow")
)
const AdminInsightsFlow = lazyWithRetry(
  () => import(/* webpackChunkName: "admin-insights" */ "./insights/AdminInsightsFlow")
)
const AdminMembersFlow = lazyWithRetry(
  () => import(/* webpackChunkName: "admin-members" */ "@/admin/members/AdminMembersFlow")
)
const AdminLibraryFlow = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-library" */ "@/admin/content-library/AdminLibraryFlow"
    )
)
const AdminEventsFlow = lazyWithRetry(
  () => import(/* webpackChunkName: "admin-events" */ "@/admin/events/AdminEventsFlow")
)
const AdminProductsFlow = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "admin-products" */ "@/admin/products/AdminProductsFlow")
)
const AdminIntegrationsFlow = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-integrations" */ "@/admin/integrations/AdminIntegrationsFlow"
    )
)
const AdminAppearanceFlow = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-appearance" */ "@/admin/appearance/AdminAppearanceFlow"
    )
)

const AutomationsReportPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "automations-report-page" */ "@/admin/automation/AutomationsReportPage"
    )
)

const AdminAIFlow = lazyWithRetry(
  () => import(/* webpackChunkName: "admin-ai" */ "@/admin/ai/AdminAIFlow")
)

const AdminOnboardingCheckListPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-onboarding-checklist" */ "@/admin/onboarding-checklist/AdminOnboardingChecklistPage"
    )
)

function AdminConsoleFlows() {
  const activeOrganization = useActiveOrganization()

  return (
    <MetaDetails metaTitle={"Admin Console"}>
      <Switch>
        <PermissionedRoute
          key={"AdminDashboard"}
          path={ROUTE_NAMES.ADMIN.DASHBOARD}
          permission={"organization_settings.manage"}
          entityKey={activeOrganization}
        >
          <AdminDashboard />
        </PermissionedRoute>

        <PermissionedRoute
          key={"AdminDashboard"}
          path={ROUTE_NAMES.ADMIN.MEMBERS.ROOT}
          permission={"organization_settings.manage"}
          entityKey={activeOrganization}
        >
          <AdminMembersFlow />
        </PermissionedRoute>

        <PermissionedRoute
          key={"AdminDashboard"}
          path={ROUTE_NAMES.ADMIN.CONTENT.ROOT}
          permission={"organization_settings.manage"}
          entityKey={activeOrganization}
        >
          <AdminLibraryFlow />
        </PermissionedRoute>

        <PermissionedRoute
          key={"AdminEventsFlow"}
          path={ROUTE_NAMES.ADMIN.EVENTS.ROOT}
          permission={"organization_settings.manage"}
          entityKey={activeOrganization}
        >
          <AdminEventsFlow />
        </PermissionedRoute>

        <Redirect
          from={ROUTE_NAMES.ADMIN.PRODUCTS.MEDIA_LIBRARY.ROOT}
          to={ROUTE_NAMES.ADMIN.CONTENT.MEDIA_ASSETS.ROOT}
        />

        <PermissionedRoute
          key={"AdminProductsFlow"}
          path={ROUTE_NAMES.ADMIN.PRODUCTS.ROOT}
          permission={"organization_settings.manage"}
          entityKey={activeOrganization}
        >
          <AdminProductsFlow />
        </PermissionedRoute>

        <PermissionedRoute
          key={"AutomationReportPage"}
          path={ROUTE_NAMES.ADMIN.AUTOMATIONS}
          permission={"organization_settings.manage"}
          entityKey={activeOrganization}
        >
          <AutomationsReportPage />
        </PermissionedRoute>

        <PermissionedRoute
          key={"AdminInsightsFlow"}
          path={ROUTE_NAMES.ADMIN.INSIGHTS.ROOT}
          permission={[
            "organization_settings.manage",
            "products_reports.read",
            "group_reports.read",
          ]}
          entityKey={activeOrganization}
        >
          <AdminInsightsFlow />
        </PermissionedRoute>

        <PermissionedRoute
          key={"AdminIntegrationsFlow"}
          path={ROUTE_NAMES.ADMIN.INTEGRATIONS.ROOT}
          permission={"organization_settings.manage"}
          entityKey={activeOrganization}
        >
          <AdminIntegrationsFlow />
        </PermissionedRoute>

        <PermissionedRoute
          key={"AdminAppearanceFlow"}
          path={ROUTE_NAMES.ADMIN.APPEARANCE.ROOT}
          permission={"organization_settings.manage"}
          entityKey={activeOrganization}
        >
          <AdminAppearanceFlow />
        </PermissionedRoute>

        <PermissionedRoute
          key={"AdminSettingsFlow"}
          path={ROUTE_NAMES.ADMIN.SETTINGS.ROOT}
          permission={"organization_settings.manage"}
          entityKey={activeOrganization}
        >
          <AdminSettingsFlow />
        </PermissionedRoute>

        <PermissionedRoute
          key={"AdminAIFlow"}
          path={ROUTE_NAMES.ADMIN.AI.ROOT}
          permission={"organization_settings.manage"}
          entityKey={activeOrganization}
        >
          <AdminAIFlow />
        </PermissionedRoute>

        <PermissionedRoute
          key={"AdminOnboardingCheckListPage"}
          path={ROUTE_NAMES.ADMIN.ONBOARDING_CHECKLIST}
          permission={"onboarding_checklist.read"}
          entityKey={activeOrganization}
        >
          <AdminOnboardingCheckListPage />
        </PermissionedRoute>
        <Route>{renderRedirects()}</Route>
      </Switch>
    </MetaDetails>
  )

  /** Redirects for admin-level routes we have renamed */
  function renderRedirects() {
    return (
      <Switch>
        <Redirect exact from={"/admin/people"} to={ROUTE_NAMES.ADMIN.MEMBERS.ROOT} />
        <Redirect
          exact
          from={"/admin/people/members"}
          to={ROUTE_NAMES.ADMIN.MEMBERS.ROOT}
        />
        <Redirect
          exact
          from={"/admin/people/admins"}
          to={ROUTE_NAMES.ADMIN.MEMBERS.ROOT}
        />
        <Redirect
          exact
          from={"/admin/people/groups"}
          to={ROUTE_NAMES.ADMIN.MEMBERS.GROUPS.ROOT}
        />
        <Redirect
          exact
          from={"/admin/people/invites"}
          to={ROUTE_NAMES.ADMIN.MEMBERS.INVITES.ROOT}
        />
        <Redirect
          exact
          from={"/admin/people/profile-fields"}
          to={ROUTE_NAMES.ADMIN.MEMBERS.PROFILE_FIELDS.ROOT}
        />
        <Redirect
          exact
          from={"/admin/people/applications"}
          to={ROUTE_NAMES.ADMIN.MEMBERS.APPLICATIONS.ROOT}
        />
        <Redirect
          exact
          from={"/admin/settings/community"}
          to={ROUTE_NAMES.ADMIN.SETTINGS.ROOT}
        />
        <Redirect
          exact
          from={"/admin/learning-content"}
          to={ROUTE_NAMES.ADMIN.PRODUCTS.ROOT}
        />
        <Redirect
          exact
          from={"/admin/learning-content/experiences"}
          to={ROUTE_NAMES.ADMIN.PRODUCTS.ROOT}
        />
        <Redirect
          exact
          from={"/admin/learning-content/media-library"}
          to={ROUTE_NAMES.ADMIN.PRODUCTS.MEDIA_LIBRARY.ROOT}
        />
        <Redirect
          exact
          from={"/admin/marketing"}
          to={ROUTE_NAMES.ADMIN.PRODUCTS.PUBLIC_PAGES.ROOT}
        />
        <Redirect
          exact
          from={"/admin/marketing/public-pages"}
          to={ROUTE_NAMES.ADMIN.PRODUCTS.PUBLIC_PAGES.ROOT}
        />
        <Redirect
          exact
          from={"/admin/marketing/transactions"}
          to={ROUTE_NAMES.ADMIN.PRODUCTS.TRANSACTIONS.ROOT}
        />
        <Redirect
          exact
          from={"/admin/marketing/memberships"}
          to={ROUTE_NAMES.ADMIN.PRODUCTS.MEMBERSHIPS.ROOT}
        />
        <Redirect
          exact
          from={"/admin/marketing/discounts"}
          to={ROUTE_NAMES.ADMIN.PRODUCTS.DISCOUNTS.ROOT}
        />
        <Redirect
          exact
          from={"/admin/marketing/discounts/new"}
          to={ROUTE_NAMES.ADMIN.PRODUCTS.DISCOUNTS.CREATE}
        />
        <Redirect
          exact
          from={"/admin/marketing/discounts/:discountId/edit"}
          to={ROUTE_NAMES.ADMIN.PRODUCTS.DISCOUNTS.EDIT}
        />
        <Redirect
          exact
          from={"/admin/marketing/discounts/:discountId"}
          to={ROUTE_NAMES.ADMIN.PRODUCTS.DISCOUNTS.DETAIL}
        />
        <Redirect
          exact
          from={"/admin/marketing/memberships"}
          to={ROUTE_NAMES.ADMIN.PRODUCTS.MEMBERSHIPS.ROOT}
        />
        <Redirect exact from={"/admin/reporting"} to={ROUTE_NAMES.ADMIN.INSIGHTS.ROOT} />
        <Redirect
          exact
          from={"/admin/customization"}
          to={ROUTE_NAMES.ADMIN.APPEARANCE.ROOT}
        />
        <Redirect
          exact
          from={"/admin/customization/appearance"}
          to={ROUTE_NAMES.ADMIN.APPEARANCE.ROOT}
        />
        <Redirect
          exact
          from={"/admin/customization/labels"}
          to={ROUTE_NAMES.ADMIN.APPEARANCE.LABELS.ROOT}
        />
        <Redirect
          exact
          from={"/admin/settings/appearance"}
          to={ROUTE_NAMES.ADMIN.APPEARANCE.ROOT}
        />
        <Redirect
          exact
          from={"/admin/customization/domain"}
          to={ROUTE_NAMES.ADMIN.APPEARANCE.DOMAIN.ROOT}
        />
        <Redirect
          exact
          from={"/admin/settings/domain"}
          to={ROUTE_NAMES.ADMIN.APPEARANCE.DOMAIN.ROOT}
        />
        <Redirect
          from={"/admin/settings/integrations"}
          to={ROUTE_NAMES.ADMIN.INTEGRATIONS.ROOT}
        />
        <Redirect
          from={"/admin/settings/integrations/zapier"}
          to={ROUTE_NAMES.ADMIN.INTEGRATIONS.ZAPIER}
        />
        <Redirect to={ROUTE_NAMES.COMMUNITY.HOME.ROOT} />
      </Switch>
    )
  }
}

export default AdminConsoleFlows
